export function anonymousUser() {
  let token = localStorage.getItem("accessToken");

  if (token) {
    return false;
  } else {
    return true;
  }
}

//api url
export function getApiUrl() {

  return window.API_URL;
  // if (hostname.includes("localhost")) {
  //   return "https://localhost:7143";
  // } else if (hostname.includes("-dev")) {
  //   return "https://api-dev.usapath.com";
  // } else {
  //   return "https://api.usapath.com";
  // }
}

// Generate a random GUID
export function generateGuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
