import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/store";
import { createUser } from "store/thunk/authThunk";

const Iframe = () => {
  const dispatch = useAppDispatch();
  const getUserData = JSON.parse(localStorage.getItem("userData") || "{}");
  const basic_auth_token = localStorage.getItem('basic_auth_token');

  const [iframeUrl, setIframeUrl] = useState("");
  const [localToken, setLocalToken] = useState<any>("");
  const [formData, setFormData] = useState({ basic_auth_token: "", firstName: "", middleName: "", lastName: "", email: "" });
  const [isFormEdited, setIsFormEdited] = useState(false);


  // const hostname = window.location.hostname;
  let baseIframeUrl = window.FORM_UI_URL;
  // if (hostname === 'localhost') {
  //   baseIframeUrl = 'http://localhost:3000/iframe-listener';
  // } else if (hostname === 'my-dev.legalcaseapp.com') {
  //   baseIframeUrl = 'https://my-dev.usapath.com/iframe-listener';
  // } else {
  //   baseIframeUrl = 'https://my.usapath.com/iframe-listener';
  // }
  console.log("baseIframeUrl ====>", baseIframeUrl);
  
  useEffect(() => {
    if (getUserData && !isFormEdited) {
      const name = getUserData?.name?.split(" ");
      const tempFormData = {
        basic_auth_token: basic_auth_token || "",
        firstName: name?.[0],
        middleName: name?.[1],
        lastName: name?.[2],
        email: getUserData?.email
      }
      setFormData(tempFormData);
      setIsFormEdited(true);
    }

  }, [basic_auth_token, getUserData, isFormEdited])

  useEffect(() => {
    const loadInitialValues = () => {
      const getJWTToken = localStorage.getItem("jwtToken");
      setLocalToken(getJWTToken);
    };

    loadInitialValues();

    const handleStorageChange = (event: any) => {
      if (event.key === "jwtToken") {
        setLocalToken(event.newValue);
      }
    };
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIframeUrl(`${baseIframeUrl}?authToken=${localToken}`);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    let tempFormData = {
      ...formData,
      [name]: value
    }
    setFormData(tempFormData)
  };

  const handleCreateUser = () => {
    let apiData = {
      authToken: formData?.basic_auth_token,
      userData: {
        firstName: formData?.firstName,
        lastName: formData?.lastName,
        middleName: formData?.middleName,
        email: formData?.email
      }
    }
    dispatch(createUser(apiData));
    localStorage.setItem('basic_auth_token', formData?.basic_auth_token)
  };

  return (
    <section className="p-3">
      <div className="card">

        <div className="d-flex mb-3">
          <input
            name="basic_auth_token"
            type="text"
            className="form-control col me-2"
            placeholder="Enter basic auth token"
            value={formData?.basic_auth_token}
            onChange={(e) => handleChange(e)}
          />
          <input
            name="firstName"
            type="text"
            className="form-control col me-2"
            placeholder="Enter first name"
            value={formData?.firstName}
            onChange={(e) => handleChange(e)}
          />
          <input
            name="middleName"
            type="text"
            className="form-control col me-2"
            placeholder="Enter middle name"
            value={formData?.middleName}
            onChange={(e) => handleChange(e)}
          />
          <input
            name="lastName"
            type="text"
            className="form-control col me-2"
            placeholder="Enter last name"
            value={formData?.lastName}
            onChange={(e) => handleChange(e)}
          />
          <input
            name="email"
            type="text"
            className="form-control col me-2"
            placeholder="Enter email"
            value={formData?.email}
            onChange={(e) => handleChange(e)}
          />
          <button type="button" onClick={() => handleCreateUser()} className="btn btn-success">
            Create User
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="d-flex">
            <input
              name="token"
              type="text"
              className="form-control col me-2"
              placeholder="Enter Token"
              value={localToken}
              disabled
            />
            <button type="submit" className="btn btn-primary">
              Load Iframe
            </button>
          </div>
        </form>
      </div>
      <div className="card mt-3">
        {iframeUrl ? (
          <iframe
          title={`third_party_integration${iframeUrl}`}
            src={iframeUrl}
            style={{ width: "100%", height: "100vh", border: "none" }}
          ></iframe>
        ) : (
          <h5 className="text-center">Load Iframe</h5>
        )}
      </div>
    </section>
  );
};

export default Iframe;
