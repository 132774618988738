import { createAsyncThunk } from "@reduxjs/toolkit";
import dotnetApiClient from "../../config/dotnetApiClient";
import basicDotnetApiClient from "../../config/basicDotnetApiClient";

export const generateToken: any = createAsyncThunk(
  "createUser",
  async (_request: any, { dispatch }) => {
    const { basicAuthToken, userId } = _request;
    try {
      const response = await basicDotnetApiClient(false, true, basicAuthToken).post(`/v1/management/users/${userId}/generateToken`);
      if (response?.data) {
        const reponseData = response?.data;
        localStorage.setItem("jwtToken", reponseData?.jwtToken);
      }

      return response;
    } catch (error: any) {
      alert(error)
    }
  }
);

export const createUser: any = createAsyncThunk(
  "createUser",
  async (_request: any, { dispatch }) => {
    const { authToken, userData } = _request;

    try {
      const response = await basicDotnetApiClient(false, true, authToken).post('/v1/management/users', userData);
      const reponseData = response?.data;

      if (response?.data) {
        const tokenData = {
          basicAuthToken: authToken,
          userId: reponseData?.id
        }

        dispatch(generateToken(tokenData))
        localStorage.setItem("userData", JSON.stringify(reponseData));
      } else {
        alert("User already exists, please try with different email address");
      }

      return response;
    } catch (error: any) {
      alert(error)
    }
  }
);

export const getAuthConfig: any = createAsyncThunk(
  "getAuthConfig",
  async (_request: any, { dispatch }) => {
    try {

      const response: any = await dotnetApiClient(false, false).get(
        `/bootstrap`
      );

      return response;
    } catch (error: any) {
      alert(error)
    }
  }
);
